export default {
    methods: {

        /**
         *
         * @param err
         * @returns {VueI18n.TranslateResult|string|null|*}
         */
        prepareRequestError(err) {

            if (!err) return null

            // Form Error validation
            if (err.response?.status === 422) {
                const msgs = []

                err.response.data?.message && msgs.push("<b>" + err.response.data?.message + "</b>")

                if (err.response.data?.errors) {
                    Object.getOwnPropertyNames(err.response.data?.errors).map(fields => {
                        msgs.push(err.response.data?.errors[fields].join(','))
                    })
                }

                return msgs.join('<br />');
            }

            if(err.response?.status === 401 && err.response?.data?.message === 'Unauthenticated.'){
                location.reload()
            }

            if (err.response?.status === 403) {
                this.$router.back()
                return this.$t('form.unauthorized')
            }

            if (err.response?.message) return err.response.message;

            console.debug("@/mixins/handle_request_errors@handleRequestError", {err})
            return this.$t('form.unknown_error')
        },
        /**
         *
         * @param err
         * @param translationKey
         * @returns {VueI18n.TranslateResult|string|null|*}
         */
        handleRequestError(err, translationKey = null) {

            if (!err) return null

            console.debug("@/mixins/handle_request_errors", {err: err?.response?.data})

            if (err.response?.data?.message_code) {
                return this.processErrorWithMessageCode(err);
            }

            // Form Error validation
            if (err.response?.status === 422) {
                const msgs = []

                err.response?.data?.message && msgs.push("<b>" + err.response?.data?.message + "</b>")

                if (err.response?.data?.errors) {
                    Object.getOwnPropertyNames(err.response?.data?.errors).map(fields => {
                        msgs.push(err.response?.data?.errors[fields].join(','))
                    })
                }

                if (msgs.length) return msgs.join('<br />');
            }

            if(err.response?.status === 401 && err.response?.data?.message === 'Unauthenticated.'){
                location.reload()
            }

            if (err.response?.status === 403) {
                this.$router.back()
                return this.$t('form.unauthorized')
            }

            let messageParams = {}
            if (err.response?.data?.params) {
                messageParams = err.response.data.params
            } else if (err.response?.data?.query) {
                messageParams = err.response.data.query
            }

            console.debug('aqui', err.response)

            if (err.response?.data?.message_id) return this.$t((translationKey ?? '') + err.response.data.message_id, messageParams);
            if (err.response?.data?.message_code) return this.$t(err.response.data.message_code, messageParams);
            if (err.response?.data?.message) return err.response.data.message;

            return this.$t('form.unknown_error')
        },
        /**
         *
         * @param err
         * @returns {VueI18n.TranslateResult}
         */
        processErrorWithMessageCode(err) {
            let messageParams = {}
            if (err.response?.data?.params) {
                messageParams = err.response.data.params
            } else if (err.response?.data?.query) {
                messageParams = err.response.data.query
            }

            let msg = `<strong>${this.$t(err.response.data.message_code, messageParams)}</strong>`

            if (err.response.data.details && err.response.data.details.length) {
                msg += ": " + err.response.data.details?.join(", ")
            }

            return msg
        }
    }
}